import self from '../img/self.png';

import { colors } from '../colors/mainGradient';

export const info = {
  firstName: 'CK',
  lastName: 'Nakhwal',
  initials: 'CK', 
  position: 'Web Developer',
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, 
  baseColor: colors[0],
  miniBio: [
    {
      emoji: '💡',
      text: 'Creative Problem Solver',
    },
    {
      emoji: '👨🏻‍🎓',
      text: 'B.Sc. Computer Science @ UofW',
    },
    {
      emoji: '🧑🏻‍💻',
      text: 'Web Developer @ TNSE',
    },
  
    {
      emoji: '📍',
      text: 'Winnipeg, Canada 🇨🇦',
    },
  
  ],
  socials: [

    {
      link: 'https://www.linkedin.com/in/cknakhwal/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
    {
      link: 'https://www.twitter.com/cknakhwal/',
      icon: 'fa fa-twitter',
      label: 'twitter',
    },
    {
      link: 'mailto:mail@cknakhwal.com',
      icon: 'fa fa-envelope-o',
      label: 'email',
    },
  ],

  bio: " Hi, I’m CK Nakhwal! I am an innovative thinker who generates unique solutions to complex and challenging problems. Graduated with a Bachelor’s degree in Applied Computer Science. Currently working as a Web Developer at True North Sports + Entertainment. Based in Canada.",
  skills: {
    languages: ['HTML5', 'CSS3', 'JavaScript', 'Java', 'PHP', 'Swift*', 'Python*'],
    'frameworks & libraries': [
      'Bootstrap', 'jQuery', 'Node.js', 'React.js*', 'Next.js*' 
    ],
    backend: ['WHM', 'cPanel', 'Domain Management' ],
    'content management systems (CMS)': ['WordPress', 'Shopify', 'MLBAM (Disney Streaming)', 'Drupal*' ],
    'database management': [
      'MySQL', 'NoSQL' , 'PostgreSQL' 
    ],
    tools:[ 'npm', 'VS Code', 'Nova', 'GA4', 'Firebase', 'Cloudflare', 'Photoshop', 'Figma', 'Formstack' ],
    '*currently developing':['']
  },
  hobbies: [
    {
      label: 'Photography',
      emoji: '📷',
    },
    {
      label: 'Filmmaking',
      emoji: '🎥',
    },
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Books',
      emoji: '📚',
    },
    {
      label: 'Traveling',
      emoji: '✈️',
    },

    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    //   for the null ones, the button will not show up
    {
      title: 'CK Shots',
      live: 'https://ckshots.ca/',
    
      image: "https://cknakhwal.com/images/CK Shots.jpg",
    },
    {
      title: 'Winnipeg Jets',
      live: 'http://winnipegjets.com',
   
      image: 'https://cknakhwal.com/images/Winnipeg Jets.jpg',
    },
    {
      title: 'Manitoba Moose',
      live: 'https://manitobamoose.com',
   
      image: 'https://cknakhwal.com/images/Manitoba Moose.jpg',
    },
    {
      title: 'Canada Life Centre',
      live: 'https://canadalifecentre.ca',
   
      image: 'https://cknakhwal.com/images/Canada Life Centre.jpg',
    },
    {
      title: 'TNSE',
      live: 'https://TNSE.com',
   
      image: 'https://cknakhwal.com/images/TNSE.jpg',
    },
    {
      title: 'Unzipped',
      live: 'https://unzippedwpg.ca',
   
      image: 'https://cknakhwal.com/images/Unzipped.jpg',
    },
    {
      title: 'Expo Live',
      live: 'https://expolivewpg.ca',
   
      image: 'https://cknakhwal.com/images/Expo Live.jpg',
    },
    
  ],
};