import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCdw52SzdDIbKfjVWOfcW1lJRYUAjuzxVU",
  authDomain: "cknakhwal-com.firebaseapp.com",
  projectId: "cknakhwal-com",
  storageBucket: "cknakhwal-com.appspot.com",
  messagingSenderId: "1016745618424",
  appId: "1:1016745618424:web:7ee7f3942c3fa37bc1040d",
  measurementId: "G-RT9NF9EFFC"
};

const app = initializeApp(firebaseConfig);

const isGaSupported = isSupported();

const analytics = isGaSupported ? getAnalytics(app) : null;

export const logGa = (eventName, eventParams = {}) => {
  isGaSupported ? logEvent(analytics, eventName, eventParams) : console.log(eventName, eventParams);
};
